import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Checkbox, Confirm, Grid, Image, Input, Label, Message, Search } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import withParams from '../../../hocs/withParams';
import { TariffPackage } from "../../../models/tariffPackage";
import { RootStore } from "../../../stores/RootStore";
import { RatePortfolioStore } from "./RatePortfolioStore";
import '../../../index.css'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NumberFormat from 'react-number-format';

interface IStores {
	RatePortfolioStore: RatePortfolioStore
}


interface IRatePortfolioDetailsPageProps {
	navigate?: NavigateFunction;
	RootStore?: RootStore,
	params?: any
}

class RatePortfolioDetailsPage extends React.Component<IRatePortfolioDetailsPageProps> {
	private stores: IStores;

	constructor(props: any) {
		super(props);

		this.stores = { RatePortfolioStore: new RatePortfolioStore(this.props.RootStore, this.props.navigate, this.props.params.id) }
	}

	componentDidMount(): void {
		if (this.props.params.id && this.props.params.id != '0') {
			this.stores.RatePortfolioStore.getWarrantyPlusPackegeById();
			console.log('tarifacsomag id:', this.props.params.id)
		} else {
			this.stores.RatePortfolioStore.tariffPackageItem = new TariffPackage();
		}
	}

	public render() {
		return <div>
			<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={14}>
								<div style={{ marginBottom: 20 }}>
									<CustomHeader>Tarifacsomag-portfólió - {this.stores.RatePortfolioStore.tariffPackageItem?.PackageCode}</CustomHeader>
								</div>
								<Grid>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag megnevezése</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Input fluid value={this.stores.RatePortfolioStore.tariffPackageItem.Name}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('Name', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.Name} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag kódja</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Input fluid value={this.stores.RatePortfolioStore.tariffPackageItem.PackageCode}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('PackageCode', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.PackageCode} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L1</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL1 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 1) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL1(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL1}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL1?.toString() || this.stores.RatePortfolioStore.queryTextL1}
											/>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L2</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL2 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 2) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL2(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL2}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL2?.toString() || this.stores.RatePortfolioStore.queryTextL2}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L3</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL3 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 3) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL3(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL3}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL3?.toString() || this.stores.RatePortfolioStore.queryTextL3}
											/>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L4</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL4 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 4) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL4(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL4}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL4?.toString() || this.stores.RatePortfolioStore.queryTextL4}
											/>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L5</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL5 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 5) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL5(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL5}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL5?.toString() || this.stores.RatePortfolioStore.queryTextL5}
											/>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L6</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL6 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 6) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL6(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL6}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL6?.toString() || this.stores.RatePortfolioStore.queryTextL6}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L7</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL7 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 7) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL7(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL7}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL7?.toString() || this.stores.RatePortfolioStore.queryTextL7}
											/>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L8</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL8 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 8) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL8(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL8}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL8?.toString() || this.stores.RatePortfolioStore.queryTextL8}
											/>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L9</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL9 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 9) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL9(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL9}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL9?.toString() || this.stores.RatePortfolioStore.queryTextL9}
											/>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag cikkszáma L10</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.ArticleNumberL10 ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.stores.RatePortfolioStore.updateTariffPackageArticleNumber(data.result?.item, 10) }}
												onSearchChange={(event, data) => {
													this.stores.RatePortfolioStore.onChangeQueryTextL10(event, data)
												}}
												results={this.stores.RatePortfolioStore.vodafonePackageFeedByQueryTextDDLL10}
												value={this.stores.RatePortfolioStore.tariffPackageItem?.ArticleNumberL10?.toString() || this.stores.RatePortfolioStore.queryTextL10}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L1</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL1 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL1}
											</div>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L2</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL2 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL2}
											</div>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L3</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL3 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL3}
											</div>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L4</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL4 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL4}
											</div>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L5</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL5 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL5}
											</div>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L6</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL6 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL6}
											</div>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L7</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL7 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL7}
											</div>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L8</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL8 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL8}
											</div>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L9</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL9 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL9}
											</div>
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag adminisztratív megnevezése L10</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<div style={{ minHeight: '38px' }} className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdministrativeNameL10 ? "divError" : null}>
												{this.stores.RatePortfolioStore.tariffPackageItem.AdministrativeNameL10}
											</div>
										</Grid.Column>
										{/* <Grid.Column width={4}>
											<div>
												<label>Tarifacsomag csempe</label>
												{this.stores.RatePortfolioStore.tariffPackageItem.Id > 0 && (
													<span style={{ color: 'red', marginLeft: 2 }}>*</span>
												)}												
											</div>
											{this.stores.RatePortfolioStore.tariffPackageItem.Id === 0 && (
												<div style={{color: 'red', fontWeight: 'bold'}}>Csempe feltöltése csak mentés után érhető el!</div>
											)}			
											{this.stores.RatePortfolioStore.tariffPackageItem.Id > 0 && (
												<Button color='blue' onClick={() => {this.stores.RatePortfolioStore.setIsUploadConfirmOpen()}}>Feltöltés</Button>
											)}		
											<div>
												<Image src={`data:image/jpg;base64,${this.stores.RatePortfolioStore.tariffPackageItem.Logo}`}/>
											</div>											
										</Grid.Column> */}
									</Grid.Row>
									<Confirm open={this.stores.RatePortfolioStore.isUploadConfirmOpen}
										closeOnEscape={false}
										closeOnDimmerClick={false}
										onConfirm={(event) => { this.stores.RatePortfolioStore.confirmUpload(event); }}
										onCancel={(event) => { this.stores.RatePortfolioStore.cancelUpload(event); }}
										cancelButton="Mégse"
										confirmButton={this.stores.RatePortfolioStore.file ? "Feltöltés" : null}
										content={
											<div>
												<Label as="label" basic htmlFor="upload" style={{ padding: '50px 20px' }}>
													<Input hidden id={this.stores.RatePortfolioStore.uploadIdString} onChange={(event) => { this.stores.RatePortfolioStore.setFile(event) }} type="file" style={{ width: '718px' }} />
												</Label>
											</div>
										}>
									</Confirm>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Tarifacsomag ára</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.RatePortfolioStore.tariffPackageItem.PackagePrice}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('PackagePrice', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.PackagePrice} /> */}
											<NumberFormat
												value={this.stores.RatePortfolioStore.tariffPackageItem.PackagePrice}
												fluid
												thousandSeparator=" "
												suffix=" Ft"
												onValueChange={(data) => { this.stores.RatePortfolioStore.updateTariffPackage('PackagePrice', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.PackagePrice}
												customInput={Input} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Adminisztrációs díj fogyasztói ára</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.RatePortfolioStore.tariffPackageItem.AdminsitrationFee}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('AdminsitrationFee', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdminsitrationFee} /> */}
											<NumberFormat
												value={this.stores.RatePortfolioStore.tariffPackageItem.AdminsitrationFee}
												fluid
												thousandSeparator=" "
												suffix=" Ft"
												onValueChange={(data) => { this.stores.RatePortfolioStore.updateTariffPackage('AdminsitrationFee', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.AdminsitrationFee}
												customInput={Input} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Készülékkedvezmény</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.RatePortfolioStore.tariffPackageItem.DeviceDiscount}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('DeviceDiscount', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.DeviceDiscount} /> */}
											<NumberFormat
												value={this.stores.RatePortfolioStore.tariffPackageItem.DeviceDiscount}
												fluid
												thousandSeparator=" "
												suffix=" Ft"
												onValueChange={(data) => { this.stores.RatePortfolioStore.updateTariffPackage('DeviceDiscount', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.DeviceDiscount}
												customInput={Input} />
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Havidíjkedvezmény 24 havi hűség esetén</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscount}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscount', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscount} /> */}
											<NumberFormat
												value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscount}
												fluid
												thousandSeparator=" "
												suffix=" Ft"
												onValueChange={(data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscount', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscount}
												customInput={Input} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Havidíjkedvezmény időtartama 24 havi hűség esetén (hó)</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscountDuration}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscountDuration', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscountDuration} /> */}
											<NumberFormat
												value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscountDuration}
												fluid
												thousandSeparator=" "
												onValueChange={(data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscountDuration', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscountDuration}
												customInput={Input} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Havidíjkedvezmény 12 havi hűség esetén</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscountTwelve}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscountTwelve', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscountTwelve} /> */}
											<NumberFormat
												value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscountTwelve}
												fluid
												thousandSeparator=" "
												suffix=" Ft"
												onValueChange={(data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscountTwelve', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscountTwelve}
												customInput={Input} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Havidíjkedvezmény időtartama 12 havi hűség esetén (hó)</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscountDurationTwelve}
												onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscountDurationTwelve', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscountDurationTwelve} /> */}
											<NumberFormat
												value={this.stores.RatePortfolioStore.tariffPackageItem.MonthlyDiscountDurationTwelve}
												fluid
												thousandSeparator=" "
												onValueChange={(data) => { this.stores.RatePortfolioStore.updateTariffPackage('MonthlyDiscountDurationTwelve', data.value) }}
												error={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.MonthlyDiscountDurationTwelve}
												customInput={Input} />
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
									</Grid.Row>
									<Grid.Column width={10}>
										<table>
											<tr>
												<td style={{paddingBottom:'5px',paddingRight:'10px'}}>
													<div style={{fontWeight:'bold'}}>
														<label>A tarifacsomag kapcsán az Infovideóban a 21. nyilatkozat (Korlátozott szolgáltatási sebességű video opció) megjelenítése szükséges:</label>
													</div>
												</td>
												<td>
													<Checkbox checked={this.stores.RatePortfolioStore.tariffPackageItem.NeedTwentyTwo === 0} value={this.stores.RatePortfolioStore.tariffPackageItem.NeedTwentyTwo === 1 ? 0 : 1}
														onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('NeedTwentyTwo', data.value) }} />
												</td>
											</tr>
										</table>


									</Grid.Column>

									<Grid.Row>
										<Grid.Column width={8}>
											<div>
												<label>Tarifacsomag rövid leírása</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											{/* <TextArea rows={5} style={{resize: 'none', padding: 5, width: '100%', border: '1px solid rgba(34, 36, 38, 0.15)', fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif"}}
											value={this.stores.RatePortfolioStore.tariffPackageItem.Description} onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('Description', data.value) }}
											className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.Description ? "divError" : null}/> */}
											<div className={!!this.stores.RatePortfolioStore.getValidationsTariffPackage.Description ? "divError" : null}>
												<Editor

													editorState={this.stores.RatePortfolioStore.editorState}
													toolbarClassName="toolbarClassName"
													wrapperClassName="wrapperClassName"
													editorClassName="editorClassName"
													onEditorStateChange={(editorState: any) => {
														this.stores.RatePortfolioStore.onEditorStateChange(editorState);
													}}
												/>

											</div>

										</Grid.Column>
										<Grid.Column width={7}>
											<div>
												<label>Tarifacsomag csempe</label>
												{this.stores.RatePortfolioStore.tariffPackageItem.Id > 0 && (
													<span style={{ color: 'red', marginLeft: 2 }}>*</span>
												)}
											</div>
											{this.stores.RatePortfolioStore.tariffPackageItem.Id === 0 && (
												<div style={{ color: 'red', fontWeight: 'bold' }}>Csempe feltöltése csak mentés után érhető el!</div>
											)}
											{this.stores.RatePortfolioStore.tariffPackageItem.Id > 0 && (
												<Button color='blue' onClick={() => { this.stores.RatePortfolioStore.setIsUploadConfirmOpen() }}>Feltöltés</Button>
											)}
											{this.stores.RatePortfolioStore.tariffPackageItem.Id > 0 && this.stores.RatePortfolioStore.tariffPackageItem.Logo && (
												<div>
													<Image src={`data:image/jpg;base64,${this.stores.RatePortfolioStore.tariffPackageItem.Logo}`} />
												</div>
											)}
										</Grid.Column>
										{this.stores.RatePortfolioStore.tariffPackageItem.Id > 0 && (
											<Grid.Column width={1}>
												<div>
													<label>Inaktív?</label>
												</div>
												<Checkbox checked={this.stores.RatePortfolioStore.tariffPackageItem.IsActive === 0} value={this.stores.RatePortfolioStore.tariffPackageItem.IsActive === 1 ? 0 : 1}
													onChange={(event, data) => { this.stores.RatePortfolioStore.updateTariffPackage('IsActive', data.value) }} />
											</Grid.Column>
										)}
									</Grid.Row>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Confirm open={this.stores.RatePortfolioStore.showValidationErrorPopup && this.stores.RatePortfolioStore.hasValidationErrors}
							closeOnEscape={false} //disables closing the window by pressing ESC	
							closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
							onCancel={(event) => { this.stores.RatePortfolioStore.cancelShowValidationErrorPopup(); }}
							cancelButton="Bezár"
							confirmButton={false}
							content={
								<div style={{ padding: 40 }}>
									<Message style={{ maxHeight: 300, overflow: 'auto' }} negative>
										<Message.Header>Hiba a mentés közben!</Message.Header>
										<span>Validációs hibák:
											<ul style={{ marginLeft: 18 }}>{Object.values(this.stores.RatePortfolioStore.getValidationsTariffPackage).map((error) => {
												return <li key={error}>{error}</li>
											})}
											</ul>
										</span>
									</Message>
								</div>
							}
						></Confirm>
						<Grid.Row centered>
							<Grid.Column width={9} style={{ textAlign: 'left' }}>
								<Button onClick={() => { this.props.navigate('/administration/rate-portfolio') }}>Mégse</Button>
							</Grid.Column>
							<Grid.Column width={5} style={{ textAlign: 'right' }}>
								<div>
									<Button color="green" onClick={() => { this.stores.RatePortfolioStore.saveTariffPackageItem() }}>Mentés</Button>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</Provider>
		</div>
	}
}

export default withParams(withNavigation(inject('RootStore')(observer(RatePortfolioDetailsPage))));